import { computed, watch } from '@vue/composition-api'
import store from '@/store'
import { GET_PRODUCTS_IDS } from '@/store/modules/collection.module'
import {
  UPDATE_SELECT_COUNT,
  UPDATE_IS_SELECTED_ALL_PRODUCTS,
} from '@/store/modules/shop.module'
import { apiToastWarning } from '@core/utils/toast'

export const useSelections = (
  products,
  getFetchParams,
  indexes,
  shipWishlist,
) => {
  const getProductIds = (...args) => store.dispatch(GET_PRODUCTS_IDS, ...args)

  watch(indexes, newIndexes => {
    store.commit(UPDATE_SELECT_COUNT, newIndexes.length)
  })

  const fetchProductIdsUnderCurrentFilters = async (resetFilters = false) => {
    try {
      const fetchParams = getFetchParams(resetFilters, shipWishlist)
      const productIds = await getProductIds(fetchParams, shipWishlist)
      return productIds.data.data
    } catch (error) {
      console.error('Error in fetching all product ids', error)
      apiToastWarning(error)
      return []
    }
  }

  const isSelectedAllOnScreen = computed(() => {
    const displayedProductIds = products.value.flatMap(groupedProduct => groupedProduct.products.map(
        product => product._id || product.productInfo._id,
      ))
    return displayedProductIds.every(id => indexes.value.includes(id))
  })

  const selectAllProducts = (allProductIds, selectEventTrack) => {
    const newIndexesSet = new Set([...indexes.value, ...allProductIds])
    indexes.value = Array.from(newIndexesSet)
    if (selectEventTrack) selectEventTrack()
  }

  const deselectAllProducts = (
    allProductIds,
    unselectEventTrack,
    forceSelect,
  ) => {
    if (unselectEventTrack) unselectEventTrack()

    if (forceSelect === false) {
      indexes.value = []
    } else {
      indexes.value = indexes.value.filter(id => !allProductIds.includes(id))
    }
  }

  const onToggleSelectAll = async ({
    forceSelect = null,
    selectEventTrack,
    unselectEventTrack,
  }) => {
    const shouldSelectAll = forceSelect !== null ? forceSelect : !isSelectedAllOnScreen.value
    store.commit(UPDATE_IS_SELECTED_ALL_PRODUCTS, shouldSelectAll)

    const allProductIds = await fetchProductIdsUnderCurrentFilters()
    if (allProductIds.length === 0) return

    if (shouldSelectAll) {
      selectAllProducts(allProductIds, selectEventTrack)
    } else {
      deselectAllProducts(allProductIds, unselectEventTrack, forceSelect)
    }
  }

  return {
    onToggleSelectAll,
    isSelectedAllOnScreen,
  }
}
